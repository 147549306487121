import React from "react";

const AppContext = React.createContext({
  userDetails: null,
  branches: [],
  pullRequests: [],
  commits: [],
  graphAdditionalData: {},
  commitToUserMap: {}
});

export default AppContext;
