import { CssBaseline, ThemeProvider } from "@mui/material";
import { useRoutes } from "react-router-dom";
import Router from "./routes/Router";

import { baselightTheme } from "./theme/DefaultColors";
import AppProvider from "./contexts/AppProvider";

function App() {
  const routing = useRoutes(Router);
  const theme = baselightTheme;
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <CssBaseline />
        {routing}
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
