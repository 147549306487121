import React, { useState, useMemo } from "react";
import AppContext from "./AppContext";

function AppProvider({ children }) {
  const [userDetails, setUserDetails] = useState(null);
  const [branches, setBranches] = useState([]);
  const [pullRequests, setPullRequests] = useState([]);
  const [commits, setCommits] = useState([]);
  const [graphAdditionalData, setGraphAdditionalData] = useState({});
  const [commitToUserMap, setCommitToUserMap] = useState(null);

  const value = useMemo(() => {
    return {
      userDetails,
      setUserDetails,
      branches,
      setBranches,
      pullRequests,
      setPullRequests,
      commits,
      setCommits,
      graphAdditionalData,
      setGraphAdditionalData,
      commitToUserMap,
      setCommitToUserMap,
    };
  }, [branches, commits, pullRequests, userDetails]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppProvider;
